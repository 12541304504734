import React from "react";
import style_article from '../../css/article.module.css';

const inputArticle = ({
                          _value,
                          _placeholder,
                          _type,
                          _onChange,
                          _name,
                          _id,
                          _ref,
                          _onKeyPress,
                          _readOnly,
                          _min,
                          _max,
                          _onFocus,
                          _onBlur,
                          _margin,
                          _padding,
                          _disabled,
                      }) => {
    const handleChange = (e) => {
        _onChange(e);
    };
    return (
        <>
            {_readOnly != null ?
                <input
                    id={_id}
                    name={_name}
                    type={_type}
                    value={_value || ''}
                    className={style_article.inputArticle}
                    placeholder={_placeholder}
                    data-placeholder={_placeholder}
                    onChange={handleChange}
                    onKeyPress={_onKeyPress}
                    ref={_ref}
                    min={_min}
                    max={_max}
                    readOnly={_readOnly}
                    onFocus={_onFocus}
                    onBlur={_onBlur}
                    required
                    aria-required="true"
                />
                :
                <input
                    id={_id}
                    name={_name}
                    type={_type}
                    value={_value || ''}
                    className={style_article.inputArticle}
                    placeholder={_placeholder}
                    data-placeholder={_placeholder}
                    onChange={handleChange}
                    onKeyPress={_onKeyPress}
                    ref={_ref}
                    min={_min}
                    max={_max}
                    onFocus={_onFocus}
                    onBlur={_onBlur}
                    required
                    disabled={_disabled}
                    aria-required="true"
                />
            }

        </>
    );
};


export default inputArticle;