export const API = {
    LOGIN: "/app/login",
}

export const BREAKER = {
    STATE: {
        NORMAL: 0,
        USER: 1,
        DC: 2,
        ARC: 3,
        SURGE: 4,
    }
};

export const breakerStateName = (value) => {
    switch (value) {
        case BREAKER.STATE.NORMAL :
            return '연결';
        case BREAKER.STATE.USER :
            return '수동';
        case BREAKER.STATE.DC :
            return 'DC';
        case BREAKER.STATE.ARC :
            return 'Arc';
        case BREAKER.STATE.SURGE :
            return 'SPD';
        default:
            break;
    }
}

export const checkIsNull = (obj) => {
    return obj === null || obj === undefined || obj === "";
}