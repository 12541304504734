import React, {useEffect, useState} from "react";
import css_pop from "../../css/pop.module.css";
import ArticleInput from "../article/articleInput";
import ArticleBtn from "../article/articleBtn";
import Cookies from "universal-cookie/es6";
import axios from "axios";
import ArticleSelect from "../article/articleSelect";

const BreakerAdd = ({pop, breaker, g_sid, mg_sid, fun, closeF, closeM}) => {
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const [snum, setSNum] = useState();
    const [name, setName] = useState();
    const [master_sid, setMasterSid] = useState();
    const [gsid, setGSid] = useState();
    const [masters, setMasters] = useState([]);
    const [groups, setGroups] = useState([]);

    const ck_null = (obj) => {
        if (obj !== null && obj !== undefined && obj !== '') return false;
        else return true;
    }

    const _setObject = (fun) => {
        const _setValue = (e) => {
            fun(e.target.value);
        }
        return _setValue;
    }

    useEffect(() => {
        if (pop) {
            setGSid(g_sid);
            if (!ck_null(mg_sid)) {
                if (mg_sid === 0) setMasterSid(g_sid);
                else setMasterSid(mg_sid);
            }

            if (!ck_null(breaker)) {
                setSNum(breaker.snum);
                setName(breaker.name);

                axios.post('/api/app/group/get_master_list', null, {
                    headers: {Authorization: `BEARER ${cookies.get("token")}`},
                    params: {
                        m_sid: cookies.get('info').sid,
                    },
                }, {headers}).then(res => {
                    if (res.data.result_code === 1) { // 정상
                        if (res.data.list !== null && res.data.list.length > 0) {
                            let arry = [{label: "상위", value: 0}];
                            res.data.list.map((group, index) => {
                                arry.push({label: group.name, value: group.sid});
                            })
                            setMasters(arry);
                        }
                    } else if (res.data.result_code === 0) { // 회원 정보 없음

                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        } else {
            setSNum(null);
            setName(null);
        }
    }, [pop])

    useEffect(() => {
        axios.post('/api/app/group/get_slave_list', null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                sid: master_sid,
                m_sid: cookies.get('info').sid,
            },
        }, {headers}).then(res => {
            if (res.data.result_code === 1) { // 정상
                let arry = [{label: "선택", value: 0}];
                if (res.data.list !== null && res.data.list.length > 0) {
                    res.data.list.map((group, index) => {
                        arry.push({label: group.name, value: group.sid});
                    })
                }
                setGroups(arry);
            } else if (res.data.result_code === 0) { // 회원 정보 없음

            }
        }).catch(err => {
            console.log(err);
        });
    }, [master_sid])

    const set_data = () => {
        let d_sid = null;
        if (!ck_null(breaker)) d_sid = breaker.sid;

        let d_g_sid = null;
        if (gsid !== 0 && gsid !== '0') d_g_sid = gsid;
        else d_g_sid = master_sid;

        axios.post('/api/app/matching/set_data', null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                breakerSid: d_sid,
                s_num: snum,
                name: name,
                old_g_sid: g_sid,
                groupSid: d_g_sid,
                m_sid: cookies.get('info').sid,
            }
        }, {headers}).then(res => {
            if (res.data.result_code === 1) {
                fun();
                closeF();
                closeM();
            } else {
                alert(res.data.result_message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            {pop ?
                <div className={css_pop.frameWrap}>
                    <div className={css_pop.frameBackground} onClick={() => closeF()}/>
                    <div className={css_pop.frameBox}>
                        {/* 타이틀 */}
                        <div className={css_pop.frameTitle}>
                            <img
                                src={!ck_null(breaker) ? "/img/app/icon/icon_correct.png" : "/img/app/icon/icon_plus.png"}
                                alt={!ck_null(breaker) ? "correct" : "plus"} style={{paddingRight: "10px"}}/>
                            {ck_null(breaker) ? "차단기 추가" : "차단기 수정"}
                            <img src="/img/app/btn/btn_close_b.png" alt="close"
                                 className={css_pop.closeArea}
                                 onClick={closeF}
                            />
                        </div>

                        <div className={css_pop.frameTitleLine}>line</div>

                        {/* content */}
                        <div>
                            {/* 차단기 번호 */}
                            <div className={css_pop.frameInput} style={{margin: "20px auto"}}>
                                <div className={css_pop.subTitle}>차단기 번호</div>
                                <ArticleInput
                                    _disabled={!ck_null(breaker)}
                                    _type={"text"}
                                    _placeholder={"차단기 번호를 입력하세요."}
                                    _value={snum}
                                    _onChange={_setObject(setSNum)}
                                />
                            </div>

                            {/* 차단기 이름 */}
                            <div className={css_pop.frameInput} style={{margin: "20px auto"}}>
                                <div className={css_pop.subTitle}>차단기 이름</div>
                                <ArticleInput
                                    _type={"text"}
                                    _placeholder={"차단기 이름을 입력하세요."}
                                    _value={name}
                                    _onChange={_setObject(setName)}
                                />
                            </div>

                            {/* 그룹 이동 */}
                            {ck_null(breaker) ? null :
                                <>
                                    <div className={css_pop.frameInput} style={{margin: "20px auto"}}>
                                        <div className={css_pop.subTitle}>그룹 이동</div>
                                        <ArticleSelect
                                            options={masters}
                                            value={master_sid}
                                            fun={_setObject(setMasterSid)}
                                        />

                                        {/*{gsid === 0 ? null :*/}
                                        {/*    <ArticleSelect*/}
                                        {/*        options={groups}*/}
                                        {/*        value={gsid}*/}
                                        {/*        fun={_setObject(setGSid)}*/}
                                        {/*    />*/}
                                        {/*}*/}

                                        <ArticleSelect
                                            options={groups}
                                            value={gsid}
                                            fun={_setObject(setGSid)}
                                        />
                                    </div>
                                </>
                            }
                            <ArticleBtn _text={ck_null(breaker) ? "추 가" : "수 정"} _onClick={set_data}/>
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
}

export default BreakerAdd;