import React, {useEffect} from "react";
import Cookies from "universal-cookie/es6";
import axios from "axios";
import css_pop from "../../css/pop.module.css";
import ArticleBtn from "../article/articleBtn";

const BandDel = ({pop, sid, fun, closeF, closeM}) => {
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const ck_null = (obj) => {
        if (obj !== null && obj !== undefined && obj !== '') return false;
        else return true;
    }

    const delflag = () => {
        let d_sid = sid;
        if (ck_null(sid)) d_sid = null;

        axios.post("/api/app/group/delflag", null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                sid: d_sid,
            },
        }, {headers}).then(res => {
            if (res.data.result_code === 1) {
                fun();
                closeF();
                closeM();
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            {pop ?
                <div className={css_pop.popWrap}>
                    <div className={css_pop.frameBackground} onClick={() => closeF()}/>
                    <div className={css_pop.popBox}>
                        <div style={{height: "50px", display: "flex", justifyContent: "center"}}>
                            <img src={"/img/app/icon/icon_warning_r.png"} alt=""/>
                        </div>
                        <div className={css_pop.popText} style={{color: '#ff1010'}}>
                            그룹내 차단기가 모두 삭제됩니다. <br/>
                            그룹을 삭제하시겠습니까?
                        </div>
                        <div className={css_pop.btnArea}>
                            <ArticleBtn
                                _onClick={delflag}
                                _width={"130px"}
                                _text={"삭 제"}
                                _background={"#ff1010"}/>
                            <ArticleBtn
                                _onClick={closeF}
                                _width={"130px"}
                                _text={"취 소"}
                                _background={"#bbbaba"}/>
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
}

export default BandDel;