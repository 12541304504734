import React, {useCallback, useEffect, useState} from 'react'

// JS
import ArticleBtn from '../article/articleBtn'

// CSS
import css_pop from '../../css/pop.module.css'
import Cookies from "universal-cookie/es6";
import axios from "axios";

export function useBodyScrollLock() {
    const lockScroll = useCallback(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    const openScroll = useCallback(() => {
        document.body.style.removeProperty('overflow');
    }, []);

    return {lockScroll, openScroll};
}

const Setting = ({pop, breaker, closeF}) => {
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const {lockScroll, openScroll} = useBodyScrollLock();
    const [valt, setValt] = useState(0);
    const [curr, setCurr] = useState(0);

    useEffect(() => {
        if (pop) {
            lockScroll();
            setValt(breaker.setValt || 0);
            setCurr(breaker.setCurr || 0);
        } else {
            openScroll();
            setValt(0);
            setCurr(0);
        }
    }, [pop]);

    const setting = () => {
        axios.post('/api/app/breaker/set_setting', null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                snum: breaker.snum,
                setValt: valt,
                setCurr: curr
            },
        }, {headers}).then(res => {
            // console.log(res.data)
            if(res.data.result_code === 1) {
                alert("설정 신호를 보냈습니다. 설정까지 약 5~10분 정도 소요됩니다..");
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            {pop ?
                <div className={css_pop.frameWrap}>
                    <div className={css_pop.frameBackground} onClick={() => closeF()}/>
                    <div className={css_pop.frameBox}>
                        {/* 타이틀 */}
                        <div className={css_pop.frameTitle} style={{fontSize: "20px"}}>
                            <img src="/img/app/icon/icon_setting_b.png" alt="제어" style={{paddingRight: "10px"}} />
                            설 정
                            <img src="/img/app/btn/btn_close_b.png" alt="close" className={css_pop.closeArea} onClick={closeF} />
                        </div>

                        <div className={css_pop.frameTitleLine}>line</div>

                        {/* 설정 전압 */}
                        <div className={css_pop.settingBox}>
                            <div style={{ width: "90%" }} >
                                <div className={css_pop.settingTitle}>전압설정</div>
                                <div className={css_pop.btnArea}>
                                    <ArticleBtn
                                        _img={"/img/app/btn/btn_arrow_control_u.png"}
                                        _width={"50px"}
                                        _height={"50px"}
                                        _transform={"translate(0px)"}
                                        _onClick={() => setValt(valt+5)}
                                    />
                                    <div className={css_pop.settingValue}>{valt} V</div>
                                    <ArticleBtn
                                        _img={"/img/app/btn/btn_arrow_control_d.png"}
                                        _width={"50px"}
                                        _height={"50px"}
                                        _transform={"translate(0px)"}
                                        _onClick={() => setValt(valt-5)}
                                    />
                                </div>
                                <div className={css_pop.settingCaution}>( ※ 5V 단위로 설정할 수 있습니다. )</div>
                            </div>
                        </div>

                        {/* 설정 전류 */}
                        <div className={css_pop.settingBox} style={{ padding: "0 0 30px" }}>
                            <div style={{ width: "90%" }} >
                                <div className={css_pop.settingTitle}>전류설정</div>
                                <div className={css_pop.btnArea}>
                                    <ArticleBtn
                                        _img={"/img/app/btn/btn_arrow_control_u.png"}
                                        _width={"50px"}
                                        _height={"50px"}
                                        _transform={"translate(0px)"}
                                        _onClick={() => setCurr(curr+5)}
                                    />
                                    <div className={css_pop.settingValue}>{curr} A</div>
                                    <ArticleBtn
                                        _img={"/img/app/btn/btn_arrow_control_d.png"}
                                        _width={"50px"}
                                        _height={"50px"}
                                        _transform={"translate(0px)"}
                                        _onClick={() => setCurr(curr-5)}
                                    />
                                </div>
                                <div className={css_pop.settingCaution}>( ※ 5A 단위로 설정할 수 있습니다. )</div>
                            </div>
                        </div>
                        <div><ArticleBtn _text={"설 정"} _onClick={setting} /></div>
                    </div>
                </div>
                : null}
        </>
    )
}

export default Setting;