import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from "universal-cookie/es6";

// JS
import SettingPop from '../pop/setting_pop'

// CSS
import css_area from '../../css/area.module.css'

function BreakerName({img, style, breaker, g_sid, master_sid, fun}) {

    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    return (
        <div className={css_area.user} style={style}>
            {img !== null && img !== undefined && img !== '' ? <div><img src={img} alt="" style={{ display: "flex", alignItems: "center", width: "44px" }}/></div> : null}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src="/img/app/icon/icon_breaker_b.png" alt="유저"/>
                <div style={{paddingLeft: "10px", overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{breaker.name}</div>
            </div>
            <div style={{position: "absolute", paddingRight: '14px', right: 0}}>
                <SettingPop
                    g_sid={g_sid}
                    mg_sid={master_sid}
                    breaker={breaker}
                    display={3}
                    fun={fun}
                />
            </div>
        </div>
    )
};

export default BreakerName;