import React from 'react'

// CSS
import css_article from '../../css/article.module.css'

const ArticleSelect = ({style, options, value, fun, label, groupOption1, groupOption2}) => {
    return (
        <select
            value={value}
            className={css_article.select}
            style={style}
            onChange={fun}
        >
            {options.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
            ))}
        </select>
    )
}

export default ArticleSelect;