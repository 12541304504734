import React from "react";
import Cookies from "universal-cookie/es6";
import css_pop from "../../css/pop.module.css";
import ArticleBtn from "../article/articleBtn";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const BreakerDel = ({pop, breaker, g_sid, fun, closeF, closeM}) => {
    const navigate = useNavigate();
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const ck_null = (obj) => {
        if(obj !== null && obj !== undefined && obj !== '') return false;
        else return true;
    }

    const set_delete = () => {
        if(ck_null(breaker)) return alert("잘못된 정보입니다.");
        else {
            axios.post("/api/app/matching/delete", null, {
                headers: {Authorization: `BEARER ${cookies.get("token")}`},
                params: {
                    groupSid: g_sid,
                    breakerSid: breaker.sid,
                },
            }, {headers}).then(res => {
                if (res.data.result_code === 1) {
                    navigate("/app/list", {replace: true});
                } else {

                }
            }).catch(err => {
                console.log(err);
            })
        }
    }

    return (
        <>
            {pop ?
                <div className={css_pop.popWrap}>
                    <div className={css_pop.frameBackground} onClick={() => closeF()}/>
                    <div className={css_pop.popBox}>
                        <div style={{height: "50px", display: "flex", justifyContent: "center"}}>
                            <img src={"/img/app/icon/icon_warning_r.png"} alt=""/>
                        </div>
                        <div className={css_pop.popText} style={{color: '#ff1010'}}>
                            차단기를 삭제하시겠습니까?
                        </div>
                        <div className={css_pop.btnArea}>
                            <ArticleBtn
                                _onClick={() => set_delete()}
                                _width={"130px"}
                                _text={"삭 제"}
                                _background={"#ff1010"}/>
                            <ArticleBtn
                                _onClick={closeF}
                                _width={"130px"}
                                _text={"취 소"}
                                _background={"#bbbaba"}/>
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
}

export default BreakerDel;