import React, {useEffect, useState} from 'react'
import css_pop from "../../css/pop.module.css";
import ArticleInput from "../article/articleInput";
import ArticleBtn from "../article/articleBtn";
import Cookies from "universal-cookie/es6";
import axios from "axios";
import ArticleSelect from "../article/articleSelect";

const BandAdd = ({pop, band, mg_sid, fun, fun2, closeF, closeM}) => {
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const [name, setName] = useState();
    const [master_sid, setMasterSid] = useState();
    const [options, setOptions] = useState([]);

    const _setName = (e) => {
        setName(e.target.value);
    }
    const _setMasterSid = (e) => {
        setMasterSid(e.target.value);
    }

    const ck_null = (obj) => {
        if (obj !== null && obj !== undefined && obj !== '') return false;
        else return true;
    }

    useEffect(() => {
        if (pop) {
            if (!ck_null(band)) {
                setName(band.name);
                setMasterSid(band.masterBandSid);

                axios.post('/api/app/group/get_master_list', null, {
                    headers: {Authorization: `BEARER ${cookies.get("token")}`},
                    params: {
                        m_sid: cookies.get('info').sid,
                    },
                }, {headers}).then(res => {
                    if (res.data.result_code === 1) { // 정상
                        if(res.data.list !== null && res.data.list.length > 0) {
                            let arry = [{label: "상위", value: 0}];
                            res.data.list.map((group, index) => {
                                arry.push({label: group.name, value: group.sid});
                            })
                            setOptions(arry);
                        }
                    } else if (res.data.result_code === 0) { // 회원 정보 없음

                    }
                }).catch(err => {
                    console.log(err);
                });
            } else if (!ck_null(mg_sid)) {
                setMasterSid(mg_sid);
            } else {
            }
        }
    }, [pop]);

    const set_data = () => {
        if(ck_null(name)) return alert('그룹 명을 입력해주세요.');
        let d_sid = null;
        if (!ck_null(band)) {
            if (!ck_null(band.sid)) d_sid = band.sid;
        }

        axios.post("/api/app/group/set_data", null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                sid: d_sid,
                masterBandSid: master_sid,
                mbrsSid: cookies.get('info').sid,
                name: name,
            },
        }, {headers}).then(res => {
            if (res.data.result_code === 1) {
                fun();
                closeF();
                closeM();
                if (master_sid === 0) {
                    fun2();
                }
            } else if (res.data.result_code === 2) {
                alert('이미 사용 중인 그룹 명입니다.');
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            {pop ?
                <div className={css_pop.frameWrap}>
                    <div className={css_pop.frameBackground} onClick={() => closeF()}/>
                    <div className={css_pop.frameBox}>
                        {/* 타이틀 */}
                        <div className={css_pop.frameTitle}>
                            <img
                                src={!ck_null(band) ? "/img/app/icon/icon_correct.png" : "/img/app/icon/icon_plus.png"}
                                alt={!ck_null(band) ? "correct" : "plus"} style={{paddingRight: "10px"}}/>
                            {!ck_null(band)
                                ? band.masterBandSid === 0 ? "그룹 수정" : "하위그룹 수정"
                                : !ck_null(master_sid)
                                    ? "하위그룹 추가"
                                    : "그룹 추가"}
                            <img src="/img/app/btn/btn_close_b.png" alt="close"
                                 className={css_pop.closeArea}
                                 onClick={closeF}
                            />
                        </div>

                        <div className={css_pop.frameTitleLine}>line</div>

                        <div className={css_pop.articleBox}>
                            {/* 그룹명 */}
                            <div className={css_pop.frameInput} style={{margin: "20px auto"}}>
                                <div className={css_pop.subTitle}>그룹명</div>
                                <ArticleInput
                                    _type={"text"}
                                    _placeholder={"그룹 명을 입력해주세요."}
                                    _value={name}
                                    _onChange={_setName}
                                />
                            </div>

                            {/* 그룹 이동 */}
                            {ck_null(band) ? null :
                                band.masterBandSid === 0 ? null :
                                    <div className={css_pop.frameInput} style={{margin: "20px auto"}}>
                                        <div className={css_pop.subTitle}>그룹 이동</div>
                                        <ArticleSelect
                                            options={options}
                                            value={master_sid}
                                            fun={_setMasterSid}
                                        />
                                    </div>
                            }
                        </div>
                        <ArticleBtn _text={ck_null(band) ? "추 가" : "수 정"} _onClick={set_data}/>
                    </div>
                </div>
                : null}
        </>
    )
}

export default BandAdd;