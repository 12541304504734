import React from 'react'
import {useNavigate} from "react-router-dom";
import {BREAKER, breakerStateName} from "../../../config";

import SettingPop from '../pop/setting_pop'

import css_com from '../../css/com.module.css'

const Breaker = ({band, list, fun}) => {
    const navigate = useNavigate();

    const go_breaker = (num) => {
        navigate('/app/info', {state: {g_sid: band.sid, b_sid: num, master_sid: band.masterBandSid}});
    }

    return (
        <div className={css_com.wrap} style={list !== null && list !== undefined && list.length > 0 ? null : {padding: 0}}>
            <div className={css_com.listTitleArea}>
                <div style={{width: "80%"}}>
                    <img src="/img/app/icon/icon_breaker_b.png" alt="차단기"/>
                    <div className={css_com.subTitle}>차단기목록</div>
                </div>
                <div><SettingPop display={2} band={band} fun={fun}/></div>
            </div>
            <ul className={css_com.breakerList} style={{padding: "0", margin: "0"}}>
                {list !== null && list !== undefined && list.length > 0 ?
                    list.map((breaker, index) => (
                        <li
                            className={css_com.breaker_list_li}
                            key={band.name + "_breaker_" + index}
                            style={breaker.state !== BREAKER.STATE.NORMAL ? {border: "3px solid #FF1010"} : null}
                        >
                            <div>
                                <div className={css_com.breaker_list_li_name}>
                                    <div>{breaker.name}</div>
                                </div>
                                <div className={css_com.breaker_list_li_state}>
                                    <div style={breaker.state !== BREAKER.STATE.NORMAL ? {font: 'normal normal bold 1.2rem Pretendard', color: '#FF1010'} : null}>
                                        {breakerStateName(breaker.state)}
                                    </div>
                                    <div>
                                        <div>{breaker.valt || 0}V</div>
                                        <div>{breaker.curr || 0}A</div>
                                    </div>
                                </div>
                            </div>
                            <div style={breaker.state !== BREAKER.STATE.NORMAL ? {backgroundColor: '#FF1010'} : null} onClick={() => go_breaker(breaker.sid)}>
                                <img src="/img/app/btn/btn_arrow_w2.png" alt="오른쪽화살표"/>
                            </div>
                        </li>
                    ))
                    : null}
            </ul>
        </div>
    )
}

export default Breaker;