import React, {useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie/es6";

// JS
import ArticleInput from '../components/article/articleInput'
import ArticleBtn from '../components/article/articleBtn'

// CSS
import css_pages from '../css/pages.module.css'
import {useNavigate} from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const headers = {'Content-Type': 'text/plain'};

    const [id, setId] = useState();
    const [pw, setPw] = useState();

    const _setId = (e) => {
        setId(e.target.value);
    }
    const _setPw = (e) => {
        setPw(e.target.value);
    }
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            signin(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    }

    const signin = () => {
        axios.post("/api/login", null, {
            params: {
                id: id,
                pw: pw,
            }
        }, {headers}).then(res => {
            cookies.set("info", res.data.mbrs, {path: '/', maxAge: 259200});
            cookies.set("token", res.data.token, {path: '/', maxAge: 259200});
            if(cookies.get('os') === 'android') {
                window.android.callAndroid(res.data.token);
            }else if(cookies.get('os') === 'ios'){
            }
            navigate("/app/list", {replace: true});
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <div className={css_pages.wrap} style={{background: "#fff"}}>
            <div>
                <div>
                    <img src="/img/app/login_logo.png" alt="로고" style={{marginTop: "50%", width: "100%"}}/>
                    <div className={css_pages.loginInputBox}>
                        <div>
                            <img src="/img/app/icon/icon_user.png" alt="유저"/>
                            <ArticleInput
                                _type={"text"}
                                _value={id}
                                _placeholder={"아이디를 입력하세요."}
                                // _margin={"5px 0"}
                                // _padding={"0 0 0 55px"}
                                // _titleHeight={"0"}
                                _onChange={_setId}
                            />
                        </div>
                        <div>
                            <img src="/img/app/icon/icon_pw.png" alt="비밀번호"/>
                            <ArticleInput
                                _type={"password"}
                                _value={pw}
                                _placeholder={"비밀번호를 입력하세요."}
                                // _margin={"5px 0"}
                                // _padding={"0 0 0 55px"}
                                // _titleHeight={"0"}
                                _onKeyPress={handleEnter}
                                _onChange={_setPw}
                            />
                        </div>
                        <div style={{margin: "30px 0"}}>
                            <ArticleBtn
                                _text={"로 그 인"}
                                _onClick={signin}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;