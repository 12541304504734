import React, {useCallback, useEffect, useState} from 'react'
import ArticleBtn from '../article/articleBtn'

// CSS
import css_pop from '../../css/pop.module.css'
import axios from "axios";
import Cookies from "universal-cookie/es6";

export function useBodyScrollLock() {
    const lockScroll = useCallback(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    const openScroll = useCallback(() => {
        document.body.style.removeProperty('overflow');
    }, []);

    return {lockScroll, openScroll};
}

const Control = ({pop, breaker, closeF}) => {
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const {lockScroll, openScroll} = useBodyScrollLock();

    useEffect(() => {
        if (pop) lockScroll();
        else openScroll();
    }, [pop]);

    const connection = (state) => {
        axios.post('/api/app/breaker/set_control', null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                snum: breaker.snum,
                state: state,
                valt: breaker.valt,
                curr: breaker.curr
            },
        }, {headers}).then(res => {
            // console.log(res.data)
            if(res.data.result_code === 1) {
                if(state === 1) alert("연결 신호를 보냈습니다. 연결까지 약 5~10분 정도 소요됩니다..");
                else if(state === 2) alert("차단 신호를 보냈습니다. 차단까지 약 5~10분 정도 소요됩니다..");
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            {
                pop ?
                    <div className={css_pop.frameWrap}>
                        <div className={css_pop.frameBackground} onClick={() => closeF()}/>
                        <div className={css_pop.frameBox} style={{ height: "25%" }}>
                            <div className={css_pop.frameTitle} style={{fontSize: "20px"}}>
                                <img src="/img/app/icon/icon_control_b.png" alt="제어" style={{paddingRight: "10px"}}/>
                                제 어
                                <img src="/img/app/btn/btn_close_b.png" alt="close"
                                     className={css_pop.closeArea}
                                     onClick={closeF}
                                />
                            </div>

                            <div className={css_pop.frameTitleLine}>line</div>

                            <div className={css_pop.articleBox}
                                 style={{
                                     height: "80%",
                                     display: "flex",
                                     alignItems: "center"
                                 }}>
                                <ArticleBtn
                                    _text={"연 결"}
                                    _background={"#4dc745"}
                                    _height={"70%"}
                                    _onClick={() => connection(1)}
                                />
                                <ArticleBtn
                                    _text={"차 단"}
                                    _background={"#ff1010"}
                                    _height={"70%"}
                                    _onClick={() => connection(2)}
                                />
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default Control