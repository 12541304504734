import React from "react";
import Cookies from "universal-cookie/es6";
import css_pop from "../../css/pop.module.css";
import ArticleBtn from "../article/articleBtn";
import {useNavigate} from "react-router-dom";

const LogoutPop = ({pop, closeF}) => {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const logout = () => {
        cookies.remove("info", {path: '/'});
        cookies.remove("token", {path: '/'});
        if(cookies.get('os') === 'android') {
            window.android.callAndroid(0);
        }else if(cookies.get('os') === 'ios'){

        }
        navigate("/app/login", {replace: true});
    }

    return (
        <>
            {pop ?
                <div className={css_pop.popWrap}>
                    <div className={css_pop.frameBackground} onClick={() => closeF()}/>
                    <div className={css_pop.popBox} style={{padding: '0 0 20px'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row-reverse'}}>
                            <img src="/img/app/btn/btn_close_b.png" alt="닫기" onClick={closeF}/>
                        </div>
                        <div className={css_pop.popText}>
                            로그아웃 하시겠습니까?
                        </div>
                        <div className={css_pop.btnArea}>
                            <ArticleBtn
                                _onClick={logout}
                                _text={"로그아웃"}
                            />
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
}

export default LogoutPop;