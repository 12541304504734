import React, {useEffect, useState} from 'react'
import axios from "axios";
import Cookies from "universal-cookie/es6";
import {useNavigate} from "react-router-dom";
import {API, checkIsNull} from "../../config";

// JS
import Top from '../components/area/top'
import User from '../components/area/user'
import BandMaster from "../components/list/band_master";

// CSS
import css_pages from '../css/pages.module.css'

const List = () => {
    const navigate = useNavigate();
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();
    let token, info;

    try {
        token = cookies.get('token');
        info = cookies.get('info');
        if (!token) throw new Error('토큰이 존재하지 않습니다.');
    } catch {
        navigate(API.LOGIN, {replace: true});
    }

    useEffect(() => {
        get_list();
        get_state();
    }, []);

    const [list, setList] = useState([]);
    const [err, setErr] = useState(0);

    const get_list = () => {
        axios.post('/api/app/group/get_master_list', null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                m_sid: info.sid
            },
        }, {headers}).then(res => {
            if (res.data.result_code === 1) { // 정상
                setList(res.data.list);
            } else if (res.data.result_code === 0) { // 회원 정보 없음
                navigate(API.LOGIN, {replace: true});
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const get_state = () => {
        axios.post('/api/app/breaker/get_state', null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                m_sid: info.sid
            },
        }, {headers}).then(res => {
            if (res.data.result_code === 1) { // 정상
                if (!checkIsNull(res.data.state)) setErr(res.data.state);
                else setErr(0);
            } else if (res.data.result_code === 0) { // 회원 정보 없음
                navigate(API.LOGIN, {replace: true});
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <div className={css_pages.wrap}>
            <Top/>
            <User img={err === 0 ? null : "/img/app/icon/icon_warning_r.png"}
                  style={err === 0 ? null : '2px solid rgb(255, 16, 16)'} name={info.name} fun={get_list}/>
            <div>
                <ul>
                    {list !== null && list !== undefined && list.length > 0 ?
                        list.map((band, index) => (
                            <li key={"master_group_" + index}>
                                <BandMaster band={band} index={index} fun={get_list}/>
                            </li>
                        ))
                        : null}
                    {/*<li><BandMaster band={entity}/></li>*/}
                </ul>
            </div>
        </div>
    )
}

export default List;