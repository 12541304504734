import React, {useState} from 'react'

// JS
import SettingPop from '../pop/setting_pop'

// CSS
import css_area from '../../css/area.module.css'
import Setting_pop from '../pop/setting_pop';

function User({img, name, fun, style}) {

    return (
        <div className={css_area.user} style={{zIndex: 1, position: "relative", border: style}}>
            {img !== null && img !== undefined && img !== '' ?
                <div>
                    <img src={img} alt=""
                         style={{
                             display: "flex",
                             alignItems: "center",
                             width: "44px",
                         }}/>
                </div>
                : null}
            <div className={css_area.user_title}>
                <img src="/img/app/icon/icon_user.png" alt="유저"/>
                <div style={{
                    paddingRight: "20px",
                    fontWeight: "700",
                    fontSize: "20px"
                }}>
                    {name} 님
                </div>
            </div>
            <div style={{position: "absolute", paddingRight: '14px', right: 0}}>
                <Setting_pop display={0} fun={fun}/>
            </div>
        </div>
    )
}

export default User;