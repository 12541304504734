import React, {useEffect, useState} from "react";
import css_pop from "../../css/pop.module.css";
import BandAdd from "./band_add";
import BandDel from "./band_del";

const GroupEditPop = ({band, mg_sid, fun, fun2, closeM}) => {

    const [pop_edit, isPopEdit] = useState(false);
    const [pop_del, isPopDel] = useState(false);
    const _popEdit = () => {
        isPopEdit(!pop_edit);
    }
    const _popDel = () => {
        isPopDel(!pop_del);
    }

    const ch_null = (obj) => {
        if (obj !== null && obj !== undefined && obj !== '') return false;
        else return true;
    }

    return (
        <>
            <BandAdd pop={pop_edit} mg_sid={mg_sid} band={band} closeF={_popEdit} fun={fun} closeM={closeM} fun2={fun2}/>
            <BandDel pop={pop_del} sid={ch_null(band) ? null : band.sid} closeF={_popDel} fun={fun} closeM={closeM}/>
            <div className={css_pop.setPopSelectArea}>
                <div className={css_pop.setPopSelectList} onClick={() => _popEdit()}>
                    <img src={ch_null(band) ? "/img/app/icon/icon_plus.png" : "/img/app/icon/icon_correct.png"}
                         alt={ch_null(band) ? "등록" : "수정"}/>
                    <div>{!ch_null(mg_sid) ? "하위" : !ch_null(band) && band.masterBandSid !== 0 ? "하위 " : ""}그룹 {ch_null(band) ? "추가" : "수정"}</div>
                </div>
            </div>
            {ch_null(band) ? null :
                <div className={css_pop.setPopSelectArea}>
                    <div className={css_pop.setPopSelectList} onClick={() => _popDel()}>
                        <img src="/img/app/icon/icon_delete.png" alt="삭제"/>
                        <div>{!ch_null(mg_sid) ? "하위" : !ch_null(band) && band.masterBandSid !== 0 ? "하위 " : ""}그룹 삭제</div>
                    </div>
                </div>
            }
        </>
    );
}

export default GroupEditPop;