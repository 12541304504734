import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';

// JS
import AppSplash from './app/pages/splash'
import AppLogin from './app/pages/login'
import AppList from './app/pages/list'
import AppInfo from './app/pages/info'

// CSS
import './App.css';
import NotFound from "./app/pages/error";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<AppSplash/>}/>
                    <Route path="/app_check_login" element={<AppSplash/>}/>
                    <Route path="/app/login" element={<AppLogin/>}/>
                    <Route path="/app/list" element={<AppList/>}/>
                    <Route path="/app/info" element={<AppInfo/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
