import React, {useEffect, useState} from 'react'
import axios from "axios";
import Cookies from "universal-cookie/es6";

//JS
import Breaker from './breaker'
import SettingPop from '../pop/setting_pop'

// CSS
import css_com from '../../css/com.module.css'

const Group = ({band, fun, fun2}) => {
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const [list, setList] = useState([]);
    const [open, isOpen] = useState(false);
    const toggleOpen = () => {
        return isOpen(!open);
    }

    const get_list = () => {
        axios.post("/api/app/breaker/get_list", null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                g_sid: band.sid
            },
        }, {headers}).then(res => {
            setList(res.data.list);
        }).catch(err => {
            console.log(err);
        })
    }

    const get_state = () => {
        axios.post("/api/app/breaker/get_state_band", null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                b_sid: band.sid
            },
        }, {headers}).then(res => {
            if (res.data.state !== null && res.data.state !== undefined) {
                setErr(res.data.state);
            } else {
                setErr(0);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const [err, setErr] = useState(0);

    useEffect(() => {
        if (open) get_list();
    }, [open])

    useEffect(() => {
        get_state();
    }, []);

    return (
        <div
            className={css_com.groupWrap}
            style={{
                marginBottom: '5px',
                backgroundColor: open ? null : err === 0 ? null : "#FF1010",
                border: err === 0 ? null : "2px solid #FF1010"
            }}
        >
            <div
                className={css_com.groupTitleArea}
                style={{
                    borderBottom: err === 0 ? null : open ? null : '2px solid #FF1010',
                    color: err === 0 ? null : open ? null : '#FFF',
                    // backgroundColor: err === 0 ? null : "#FF1010"
                }}
            >
                <div onClick={toggleOpen}
                     style={{
                         display: "flex",
                         alignItems: "center",
                         width: "80%"
                     }}>
                    <img src={err === 0 ? "/img/app/icon/icon_subgroup.png" : open ? "/img/app/icon/icon_subgroup.png" : "/img/app/icon/icon_subgroup_w.png"} alt="그룹"/>
                    <div className={css_com.groupName}>{band.name}</div>
                    {err === 0 ? null : <img src={"/img/app/icon/icon_warning_w.png"} style={{marginLeft: '10px'}}/>}
                </div>
                <div style={{display: "flex"}}>
                    <SettingPop
                        display={5}
                        band={band}
                        fun={fun}
                        fun2={fun2}
                        open={open}
                        err={err}
                    />
                    <img
                        onClick={toggleOpen}
                        src={open
                            ? err === 0 ? "/img/app/btn/btn_arrow_b.png"  : "/img/app/btn/btn_arrow_b.png"
                            : err === 0 ? "/img/app/btn/btn_arrow_b_up.png"  : "/img/app/btn/btn_arrow_w.png"}
                    />
                </div>
            </div>
            <div style={{display: open ? "block" : "none"}}>
                <Breaker band={band} list={list} fun={get_list}/>
            </div>
        </div>
    )
}

export default Group;