import React from 'react'

// CSS
import css_article from '../../css/article.module.css'

const ArticleBtn = ({_text, _background, _width, _color, _img, _height, _onClick, _transform, _margin}) => {
    return (
        <div
            onClick={_onClick}
            className={css_article.btn}
            style={{
                height: _height || "60px",
                width: _width || "43%",
                background: _background || "#2E3097",
                color: _color || "#fff",
                margin: _margin,
                cursor: "pointer",
            }}>
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                <img src={_img} alt=""
                     style={{
                         transform: _transform || "translateX(-10px)"
                     }}
                />
                <p>
                    {_text}
                </p>

            </div>
        </div>
    )
}

export default ArticleBtn;