import React, {useEffect, useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie/es6";

// JS
import SettingPop from "../pop/setting_pop";
import Breaker from "./breaker";
import Group from "./group";

// CSS
import css_pages from "../../css/pages.module.css";
import css_com from "../../css/com.module.css";

const BandMaster = ({band, index, fun}) => {
    const headers = {'Content-Type': 'text/plain'};
    const cookies = new Cookies();

    const [bands, setBands] = useState([]);
    const [breakers, setBreakers] = useState([]);
    const [open, isOpen] = useState(false);
    const toggleOpen = () => {
        return isOpen(!open);
    }

    const get_breaker_list = () => {
        axios.post("/api/app/breaker/get_list", null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                g_sid: band.sid
            },
        }, {headers}).then(res => {
            setBreakers(res.data.list);
        }).catch(err => {
            console.log(err);
        });
    }

    const get_slave_list = () => {
        axios.post("/api/app/group/get_slave_list", null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                sid: band.sid
            },
        }, {headers}).then(res => {
            setBands(res.data.list);
        }).catch(err => {
            console.log(err);
        });
    }

    const get_state = () => {
        axios.post("/api/app/breaker/get_state_band", null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                b_sid: band.sid
            },
        }, {headers}).then(res => {
            if (res.data.state !== null && res.data.state !== undefined) {
                setErr(res.data.state);
            } else {
                setErr(0);
            }
        }).catch(err => {
            console.log(err);
        });
    }


    const get_list = () => {
        get_breaker_list();
        get_slave_list();
    }

    useEffect(() => {
        get_state();
        if (open) get_list();
    }, [open]);

    const [err, setErr] = useState(0);

    return (
        <div
            className={css_pages.groupWrap}
            style={
                open
                    ? err === 0
                        ? {borderTop: "3px solid #ffbb00"}
                        : {borderTop: "3px solid #FF1010"}
                    : err === 0
                        ? {borderTop: "2px solid #E0E2F7"}
                        : {border: "2px solid #F1F1F8", backgroundColor: "#FF1010", color: "white"}
            }
        >
            {/* 그룹 타이틀 */}
            <div className={css_pages.groupTitleArea} style={{borderBottom: open ? "3px dotted #E0E2F7" : "none"}}>
                <div
                    onClick={toggleOpen}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "80%"
                    }}>
                    <img src={err === 0 ? "/img/app/icon/icon_group.png" : open ? "/img/app/icon/icon_group.png" : "/img/app/icon/icon_group_w.png"} alt="그룹"/>
                    <div className={css_pages.groupName}>{band.name}</div>
                    {err === 0 ? null : <img src={"/img/app/icon/icon_warning_w.png"} style={{marginLeft: '10px'}}/>}
                </div>
                <div style={{display: "flex"}}>
                    {/*<SettingPop sid={band.sid} fun={get_list} display1={"block"} displayDelete0={"block"}/>*/}
                    <SettingPop band={band} fun={fun} display={1} err={err} open={open}/>
                    <img alt="접기"
                         onClick={toggleOpen}
                         src={open
                             ? err === 0 ? "/img/app/btn/btn_arrow_b.png"  : "/img/app/btn/btn_arrow_b.png"
                             : err === 0 ? "/img/app/btn/btn_arrow_b_up.png"  : "/img/app/btn/btn_arrow_w.png"}
                    />
                </div>
            </div>

            {/* 하위 그룹 리스트 및 차단기 리스트 */}
            <div style={{display: open ? "block" : "none"}}>
                {/* 차단기 리스트 */}
                <Breaker band={band} list={breakers} fun={get_breaker_list}/>

                {/* 하위 그룹 리스트 */}
                <div className={css_com.listTitleArea}
                     style={{
                         width: "96%",
                         margin: "0 auto",
                         borderTop: "3px dotted #f5f5f5",
                     }}>
                    <div>
                        <img src="/img/app/icon/icon_list.png" alt="차단기"/>
                        <div className={css_com.subTitle}>하위 그룹</div>
                    </div>
                    <div>
                        <SettingPop
                            display={4}
                            fun={get_list}
                            sid={band.sid}
                            mg_sid={band.sid}
                        />
                    </div>
                </div>

                {bands !== null && bands !== undefined && bands.length > 0 ?
                    bands.map((obj, index) => (
                        <React.Fragment key={"slave_band_" + index}>
                            <Group band={obj} fun={get_slave_list} fun2={fun}/>
                        </React.Fragment>
                    ))
                    : null}
            </div>
        </div>
    );
}

export default BandMaster;