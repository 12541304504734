import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router'
import axios from "axios";
import Cookies from "universal-cookie/es6";

// JS
import Top from '../components/area/top'
import BreakerName from '../components/area/breakerName'
import ArticleBtn from '../components/article/articleBtn'
import Control from '../components/info/control'
import Setting from '../components/info/setting'

// CSS
import css_pages from '../css/pages.module.css'
import {breakerStateName} from "../../config";

const Info = () => {
    const cookies = new Cookies();
    const {state} = useLocation();
    const g_sid = state.g_sid;  // group sid
    const b_sid = state.b_sid;  // breaker sid
    const master_sid = state.master_sid // group master sid
    const headers = {'Content-Type': 'text/plain'};

    const [breaker, setBreaker] = useState({
        sid: 0,
        snum: "-",
        name: "-",
        state: 0,
        valt: 0,
        curr: 0,
        setValt: 0,
        setCurr: 0,
        regdate: "00-00-00 00:00:00"
    });

    const [fault, setFault] = useState({
        sid: 0,
        snum: "-",
        state: 0,
        valt: 0,
        curr: 0,
        setValt: 0,
        setCurr: 0,
        regdate: "00-00-00 00:00:00",
    });

    const [breaker_name, setBreakerName] = useState('-');

    const get_breaker_info = () => {
        axios.post('/api/app/breaker/get_one', null, {
            headers: {Authorization: `BEARER ${cookies.get("token")}`},
            params: {
                b_sid: b_sid,
                g_sid: g_sid,
            },
        }, {headers}).then(res => {
            if (res.data != null) {
                if (res.data.result_code === 1) {
                    if (res.data.breaker != null) {
                        let breaker = res.data.breaker;
                        setBreaker(breaker);
                        if (breaker.state === 0) {
                            setBreakerState(true);
                        } else {
                            setBreakerState(false);
                        }
                        setBreakerName(breaker.name);
                    }
                    if (res.data.fault != null) {
                        let fault = res.data.fault;
                        setFault(fault);
                    }
                } else if (res.data.result_code === 2) {
                    alert('Parameter Error !!');
                }
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        get_breaker_info();
        const interval = setInterval(() => {
            get_breaker_info();
        }, 1000 * 10)

        return () => clearInterval(interval)
    }, [])

    const [pop_control, isPopControl] = useState(false);
    const [pop_setting, isPopSetting] = useState(false);
    const _pop_control = () => {
        isPopControl(!pop_control);
    }
    const _pop_setting = () => {
        isPopSetting(!pop_setting);
    }

    const [breaker_state, setBreakerState] = useState(true);

    return (
        <>
            <Control pop={pop_control} breaker={breaker} closeF={_pop_control} infoTitle={"sdf"} state={setBreakerState}/>
            <Setting pop={pop_setting} breaker={breaker} closeF={_pop_setting}/>
            <div className={css_pages.wrap}>
                <Top/>
                {breaker_state ?
                    <BreakerName breaker={breaker} g_sid={g_sid} master_sid={master_sid} fun={get_breaker_info}/>
                    :
                    <BreakerName
                        breaker={breaker}
                        g_sid={g_sid}
                        fun={get_breaker_info}
                        master_sid={master_sid}
                        img={'/img/app/icon/icon_warning_r.png'}
                        style={{
                            border: "2px solid #ff1010"
                        }}
                    />
                }

                <div className={css_pages.groupWrap}>
                    {/* 상태 정보 */}
                    <div className={css_pages.infoBox}>
                        {breaker_state ?
                            <table>
                                <thead>
                                <tr>
                                    <th className={css_pages.th_left}>상태</th>
                                    <td className={css_pages.connectText}>연 결</td>
                                </tr>
                                </thead>
                            </table>
                            :
                            <table>
                                <thead>
                                <tr>
                                    <th className={css_pages.th_left}>상태</th>
                                    <td className={[css_pages.connectText, css_pages.disconnectText].join(' ')}>차 단</td>
                                </tr>
                                <tr>
                                    <th className={css_pages.th_left} style={{borderTop: '0'}}>차단원인</th>
                                    <td style={{borderTop: '0'}}>{breakerStateName(breaker.state)}</td>
                                </tr>
                                <tr>
                                    <th className={css_pages.th_left} style={{borderTop: '0'}}>차단시간</th>
                                    <td style={{borderTop: '0'}}>{fault.regdate}</td>
                                </tr>
                                </thead>
                            </table>
                        }
                    </div>

                    {/* 전압, 전류 정보 */}
                    <div className={css_pages.infoBox} style={{paddingTop: "0"}}>
                        <table>
                            <thead>
                            <tr>
                                <th className={css_pages.th_top}>전압 ( V )</th>
                            </tr>
                            <tr>
                                <td style={{height: "120px"}}>
                                    {breaker_state ?
                                        <>
                                            <div>현재 값 : <span>{breaker.valt}</span> V</div>
                                            <div>설정 값 : <span>{breaker.setValt}</span> V</div>
                                        </>
                                        :
                                        <>
                                            <div className={css_pages.disconnectText}>차단 값 : <span>{fault.valt}</span> V</div>
                                            <div>설정 값 : <span>{fault.setValt}</span> V</div>
                                        </>
                                    }
                                </td>
                            </tr>
                            </thead>
                        </table>
                        <table style={{marginLeft: "10px"}}>
                            <thead>
                            <tr>
                                <th className={css_pages.th_top}>전류 ( A )</th>
                            </tr>
                            <tr>
                                <td>
                                    {breaker_state ?
                                        <>
                                            <div>현재 값 : <span>{breaker.curr}</span> A</div>
                                            <div>설정 값 : <span>{breaker.setCurr}</span> A</div>
                                        </>
                                        :
                                        <>
                                            <div className={css_pages.disconnectText}>차단 값 : <span>{fault.curr}</span> A</div>
                                            <div>설정 값 : <span>{fault.setCurr}</span> A</div>
                                        </>
                                    }
                                </td>
                            </tr>
                            </thead>
                        </table>
                    </div>

                    {/* 버튼 */}
                    <div className={css_pages.infoBtnArea}>
                        <ArticleBtn
                            _onClick={_pop_control}
                            _text={"제 어"}
                            _img={"/img/app/icon/icon_control.png"}
                        />
                        <ArticleBtn
                            _onClick={_pop_setting}
                            _text={"설 정"}
                            _img={"/img/app/icon/icon_setting.png"}
                        />
                    </div>
                </div>
            </div>
        </>
    )

};

export default Info