import React, {useEffect} from 'react'
import Cookies from "universal-cookie/es6";
import axios from "axios";
import {useNavigate, useLocation} from "react-router-dom";
import queryString from 'query-string';

// CSS
import css_pages from '../css/pages.module.css'


const Splash = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const headers = {'Content-Type': 'text/plain'};
    const {search} = useLocation();
    let {os} = queryString.parse(search);
    const {auth} = queryString.parse(search);
    const {token} = queryString.parse(search);

    useEffect(() => {
        if (os !== null && os !== undefined && os !== '') {
            cookies.set("os", os, {path: '/', maxAge: 2592000});
            const decoding_auth = decodeURIComponent(auth);
            const decoding_token = decodeURIComponent(token);
            axios.post('/api/app/get_pubkey', null, {
                withCredentials: true,
                headers: {Authorization: `BEARER ${decoding_auth}`},
                params: {
                    token_key: decoding_token,
                },
            }, {headers}).then(res => {
                if (res.data.result_code === 1) {
                    cookies.set("info", res.data.mbrs, {path: '/', maxAge: 259200});
                    cookies.set("token", res.data.token, {path: '/', maxAge: 259200});
                    window.android.callAndroid(res.data.token);
                    navigate("/app/list", {replace: true});
                } else {
                    navigate("/app/login", {replace: true});
                }
            }).catch(err => {
                console.log(err);
                navigate("/app/login", {replace: true});
            });
        } else {
            axios.post('/api/app/get_pubkey', null, {
                withCredentials: true,
                headers: {Authorization: `BEARER ${cookies.get("token")}`},
                params: null,
            }, {headers}).then(res => {
                if (res.data.result_code === 1) {
                    cookies.set("info", res.data.mbrs, {path: '/', maxAge: 259200});
                    cookies.set("token", res.data.token, {path: '/', maxAge: 259200});
                    navigate("/app/list", {replace: true});
                } else {
                    navigate("/app/login", {replace: true});
                }
            }).catch(err => {
                console.log(err);
                navigate("/app/login", {replace: true});
            });
        }
    }, []);

    return (
        <div className={css_pages.wrap} style={{background: '#FFBB00'}}>
            <img src="/img/app/splash_logo.png" alt="로고" style={{marginTop: "50%", width: "100%"}}/>
        </div>
    )
}

export default Splash;