import React, {useEffect, useState} from "react";
import css_pop from "../../css/pop.module.css";
import BreakerAdd from "./breaker_add";
import BreakerDel from "./breaker_del";

const BreakerEditPop = ({breaker, g_sid, mg_sid, fun, closeM}) => {

    const [pop_edit, isPopEdit] = useState(false);
    const [pop_del, isPopDel] = useState(false);
    const _popEdit = () => {
        isPopEdit(!pop_edit);
    }
    const _popDel = () => {
        isPopDel(!pop_del);
    }

    const ch_null = (obj) => {
        if (obj !== null && obj !== undefined && obj !== '') return false;
        else return true;
    }

    return (
        <>
            <BreakerAdd pop={pop_edit} g_sid={g_sid} mg_sid={mg_sid} breaker={breaker} fun={fun} closeF={_popEdit} closeM={closeM} fun={fun}/>
            <BreakerDel pop={pop_del} g_sid={g_sid} breaker={breaker} fun={fun} closeF={_popDel} closeM={closeM}/>
            <div className={css_pop.setPopSelectArea}>
                <div className={css_pop.setPopSelectList} onClick={() => _popEdit()}>
                    <img src={ch_null(breaker) ? "/img/app/icon/icon_plus.png" : "/img/app/icon/icon_correct.png"}
                         alt={ch_null(breaker) ? "등록" : "수정"}/>
                    <div>차단기 {ch_null(breaker) ? "등록" : "수정"}</div>
                </div>
            </div>
            {ch_null(breaker) ? null :
                <div className={css_pop.setPopSelectArea}>
                    <div className={css_pop.setPopSelectList} onClick={() => _popDel()}>
                        <img src="/img/app/icon/icon_delete.png" alt="삭제"/>
                        <div>차단기 삭제</div>
                    </div>
                </div>
            }
        </>
    );
}

export default BreakerEditPop;