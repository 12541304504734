import React, {useCallback, useEffect, useState} from 'react'

// JS
import GroupEditPop from "./group_edit_pop";
import BreakerEditPop from "./breaker_edit_pop";

// CSS
import css_pop from '../../css/pop.module.css'

export function useBodyScrollLock() {
    const lockScroll = useCallback(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    const openScroll = useCallback(() => {
        document.body.style.removeProperty('overflow');
    }, []);

    return {lockScroll, openScroll};
}

const Setting_pop = ({display, band, breaker, sid, g_sid, mg_sid, fun, fun2, open, err}) => {
    const {lockScroll, openScroll} = useBodyScrollLock();
    const [modal, isModal] = useState(false);

    useEffect(() => {
        if (modal) lockScroll();
        else openScroll();
    }, [modal]);

    const showModal = () => {
        isModal(true);
    };
    const closeModal = () => {
        isModal(false);
    };

    const ck_null = (obj) => {
        return !(obj !== null && obj !== undefined && obj !== '');
    }

    return (
        <>
            {modal ? <div style={{position: 'fixed', zIndex: 2, width: '100%', height: '100%', top: 0, left: 0}}
                          onClick={closeModal}/> : null}
            <div className={css_pop.settingPopBtn}>
                <img
                    src={
                        err ?
                            err === 0
                                ? "/img/app/btn/btn_setting.png"
                                : !ck_null(open)
                                    ? open
                                        ? "/img/app/btn/btn_setting.png"
                                        : "/img/app/icon/icon_setting_w.png"
                                    : "/img/app/icon/btn_setting.png"
                            : "/img/app/btn/btn_setting.png"
                    }
                    alt="관리버튼"
                    onClick={showModal}
                />
                {modal ?
                    <div className={css_pop.settingPop} style={{zIndex: 3}}>
                        <div className={css_pop.setPopTitleArea}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#fff",
                                    fontWeight: "700",
                                    fontSize: "18px"
                                }}
                            >
                                <img src="/img/app/icon/icon_setting_w.png" alt="관리"/>
                                <div>관리</div>
                            </div>
                            <img src="/img/app/btn/btn_close_w.png" alt="close" onClick={closeModal}/>
                        </div>

                        {!ck_null(display) && display === 0 ? <GroupEditPop fun={fun} closeM={closeModal}/> : null}
                        {!ck_null(display) && display === 1 ?
                            <GroupEditPop fun={fun} closeM={closeModal} band={band}/> : null}
                        {!ck_null(display) && display === 2 ?
                            <BreakerEditPop fun={fun} closeM={closeModal} g_sid={band.sid}/> : null}
                        {!ck_null(display) && display === 3 ?
                            <BreakerEditPop fun={fun} closeM={closeModal} breaker={breaker} g_sid={g_sid}
                                            mg_sid={mg_sid}/> : null}
                        {!ck_null(display) && display === 4 ?
                            <GroupEditPop fun={fun} closeM={closeModal} mg_sid={mg_sid}/> : null}
                        {!ck_null(display) && display === 5 ?
                            <GroupEditPop fun={fun} closeM={closeModal} band={band} fun2={fun2}/> : null}
                    </div>
                    : null}
            </div>
        </>
    )
}

export default Setting_pop