import React, {useState} from 'react'

// JS
import Popup from '../pop/popup'

// CSS
import css_area from '../../css/area.module.css'
import LogoutPop from "../pop/logout_pop";
import {useNavigate} from "react-router-dom";

const Top = ({}) => {
    const url = window.location.pathname;
    const navigate = useNavigate();

    const [pop, isPop] = useState(false);
    const _pop = () => {
        isPop(!pop);
    }

    const go_list = () => {
        navigate(-1);
    }

    return (
        <>
            <LogoutPop pop={pop} closeF={_pop}/>
            <div className={css_area.top}>
                {url === "/app/list" ? null : <img onClick={() => go_list()} style={{position: 'absolute', left: 0}} src="/img/app/btn/btn_arrow_back.png" alt="뒤로가기"/>}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: '700',
                        color: '#ffbb00',
                        fontSize: '20px'
                    }}>
                    <img src="/img/app/logo.svg" alt="로고" style={{paddingRight: '10px'}}/>
                    차단기 모니터링
                </div>

                <div style={{display: "flex", alignItems: "center", position: 'absolute', right: 0}}>
                    <img src="/img/app/btn/btn_logout.png" alt="로그아웃" onClick={_pop}/>
                </div>
            </div>
        </>
    )
}

export default Top